import {mapActions, mapGetters, mapMutations} from "vuex";

import Vue from 'vue';
import GSignInButton from 'vue-google-signin-button';

Vue.use(GSignInButton);

export default {
    name: 'google-widget',
    data() {
        return {
            googleSignInParams: {
                client_id: process.env.VUE_APP_GOOGLE_IDENTITY,
                callback: this.onSignInSuccess,
                auto_select: false,
                // scope: "profile email",
                // response_type: "token"

            },
        }
    },
    components: {
        GSignInButton
    },
    mounted() {
        window.google.accounts.id.initialize(this.googleSignInParams)
    },
    computed: {
        ...mapGetters({
            popupRegister: 'auth/popupRegister',
            signInLoading: 'auth/singInGoogleLoading'
        })
    },
    methods: {
        ...mapActions({
            auth: `auth/SING_IN_WITH_SOCIAL`,
            link: `profile/LINK_WITH_GOOGLE`,
            fetchUser: `profile/FETCH_USER_DATA`,
            fetchFavouriteList: `favorites/GET_FAVORITES_LIST`,
        }),
        googleSignInPrompt() {
            window.google.accounts.id.prompt();
        },
        ...mapMutations({
            changePopupRegister: `auth/CHANGE_POPUP_REGISTER`,
            changePopupLogin: `auth/CHANGE_POPUP_LOGIN`,
        }),
        onSignInSuccess(googleUser) {
            // const token = googleUser.getAuthResponse('access_token').access_token;
            const token = googleUser.credential;
            const provider = 'google'
            if (this.$route.name.includes('info')) {
                this.link({token: token})
                    .then(() => {
                        this.fetchUser().then(() => {
                            this.$toasted.success(this.$t('successLinkToAccount'));
                            this.fetchFavouriteList();
                        });
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 418) {
                            this.$toasted.error(error.response.data.data.error.message);
                        }
                    })

            } else {
                this.auth({token: token, provider: provider})
                    .then(() => {
                        this.changePopupLogin(false);
                        this.fetchUser().then(() => {
                            this.$router.push({name: 'profile'}).catch(() => {
                                console.log()
                            });
                            this.fetchFavouriteList();
                        });
                        this.$router.push({name: 'profile'}).catch(() => {
                            console.log()
                        });
                        this.changePopupRegister(false);

                        this.$toasted.success(this.$t('successAuth'));
                    })
                    .catch(error => {
                        this.changePopupRegister({show: false});
                        if (error.response && error.response.status === 418) {
                            this.$toasted.error(error.response.data.data.error.message);
                        }
                    })
            }
        },
        onSignInError(error) {
            console.log(error);
        }
    }
}
